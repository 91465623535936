import { Injectable } from '@angular/core';
import { Driver } from './driver';
import { FileReaderService } from '../../app-commons/file-reader/file-reader.service';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { MocksService } from '../mocks/mocks.service';
import { SpreadsheetService } from '../../ranking/results/spreadsheet.service';
import { Race } from '../../ranking/races/race';

@Injectable({
  providedIn: 'root'
})
export class DriversService {

  drivers: Driver[] = [];

  constructor(
    private fileReaderService: FileReaderService,
    private spreadsheetService: SpreadsheetService,
    private mocks: MocksService
  ) {
  }

  requestDrivers(race: Race, options?: any): Observable<Driver[]> {
    if (race.driversSpreadsheet) {
      return this.requestDriversSpreadsheet$(race.driversSpreadsheet, options);
    } else if (race.driversCsvFile) {
      return this.requestDriversCsv(race.driversCsvFile);
    } else {
      return EMPTY;
    }
  }

  requestDriversCsv(mockPath: string): Observable<Driver[]> {
    return this.mocks.get(mockPath).pipe(
      map(csvContent => this.parseCsv(csvContent))
    );
  }

  requestDriversSpreadsheet$(fileId: string, options?: any): Observable<Driver[]> {
    return this.spreadsheetService.poll(fileId, options).pipe(
      // filter(json => json && json.feed && json.feed.entry),
      // map(json => this.parseSpreadsheetJson(json.feed.entry))
      map(csv => this.parseCsv(csv)),
    );
  }

  findByEpc(epc: string): Driver | undefined {
    return this.drivers.find(driver => driver.epc === epc);
  }

  findByStartNumber(startNumber: number): Driver | undefined {
    return this.drivers.find(driver => driver.startNumber === startNumber);
  }

  setFromCsv(csvContent) {
    this.drivers = this.parseCsv(csvContent);
  }

  private parseCsv(data: string): Driver[] {
    const json = this.fileReaderService.parseCsv(data, ',', null, null, false);
    const headers = json.shift();
    const headersMap: any = {};
    headers.forEach((value, key) => headersMap[value] = key);

    return json.map(row => ({
      startNumber: +row[headersMap.number],
      name: row[headersMap.nick] || row[headersMap.name],
      epc: row[headersMap.EPC].replace(/\s+/, ''),
      model: headersMap.model ? row[headersMap.model] : '',
      group: headersMap.group ? +row[headersMap.group] : null,
      category: +row[headersMap.category],
      stock: +row[headersMap.stock] === 1,
      women: row[headersMap.gender] === 'f',
      yt: headersMap.yt && +row[headersMap.yt] === 1
    } as Driver));
  }

  private parseSpreadsheetJson(entry: any[]): Driver[] {
    return entry.map(row => ({
      startNumber: +row.gsx$number.$t,
      name: row.gsx$nick.$t || row.gsx$name.$t,
      epc: row.gsx$epc.$t.replace(/\s+/, ''),
      model: row.gsx$model && row.gsx$model.$t,
      group: row.gsx$group ? +row.gsx$group.$t : null,
      category: +row.gsx$category.$t,
      stock: +row.gsx$stock.$t === 1,
      women: row.gsx$gender.$t === 'f',
      yt: row.gsx$yt && +row.gsx$yt.$t === 1
    } as Driver));
  }
}
